<template>
  <div class="dashboard">
    <div class="d-flex flex-column align-items-center">
      <h2 class="mb-0 p-2 text-dark">Welcome, Dr. {{ name }}</h2>
    </div>
    <div class="row">
      <div v-for="plane in planeList" :key="plane.name" class="col">
        <SessionCard :plane="plane"> </SessionCard>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { computed, defineComponent, ref, Ref } from "vue";
import SessionCard from "@/components/SessionCard.vue";
import Session from "@/models/Session";
import { SessionService } from "@/services/session.service";
import { DataService } from "@/services/data.service";

interface Plane {
  name: string | unknown;
  sessionList: Array<Session>;
  sessionDataCount: string;
}

export default defineComponent({
  name: "Dashboard",
  components: {
    SessionCard,
  },
  async beforeRouteEnter(to, from, next) {
    await SessionService.getSessionList().then(async (response) => {
      const sessionData = response.data.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      next(async (vm: any) => {
        const sessionName = [
          ...new Set(sessionData.map((session: Session) => session.plane)),
        ];

        const planeList = [];

        for (let i in sessionName) {
          const planes: Plane = {
            name: sessionName[i],
            sessionList: [],
            sessionDataCount: "",
          };
          planeList.push(planes);
        }

        for (let i in planeList) {
          for (let j in sessionData) {
            if (planeList[i].name == sessionData[j].plane) {
              const planeSession = sessionData[j];
              planeList[i].sessionList.push(planeSession);
            }
          }
        }

        for (let i in planeList) {
          for (let j in planeList[i].sessionList) {
            if (planeList[i].sessionList[j].sessionNo != 0) {
              const finishedDataCount = await DataService.getDataCountByFilter(
                planeList[i].sessionList[j].id,
                { isFinished: true }
              );
              const allDataCount = await DataService.getDataCountByFilter(
                planeList[i].sessionList[j].id,
                {}
              );

              planeList[i].sessionDataCount =
                finishedDataCount.data.data.toString() +
                "/" +
                allDataCount.data.data.toString();
            }
          }
        }
        vm.sessionData = sessionData;
        vm.planeList = planeList;
      });
    });
  },
  setup() {
    const store = useStore();

    const sessionData: Ref<Array<Session>> = ref([]);

    const planeList: Ref<Array<Plane>> = ref([]);

    const name = computed(() => {
      return (
        store.state.auth.user.firstName + " " + store.state.auth.user.lastName
      );
    });

    return {
      name,
      planeList,
      sessionData,
    };
  },
});
</script>

<style scoped>
.dashboard {
  height: 100%;
}
.card {
  margin: 50px;
  border: 3px solid #000000;
}
.demo {
  border: 3px solid;
}
.session1,
.session2 {
  border: 3px solid #69b479;
}
.session2:hover,
.session1:hover {
  background-color: #69b479;
}
.session2:disabled,
.session1:disabled {
  background-color: #adadad;
  border: 3px solid #adadad;
}
.row {
  margin: 0;
  padding: 30px;
}
</style>

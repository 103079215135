
import { useRouter } from "vue-router";
import { computed, defineComponent, PropType } from "vue";
import Session from "@/models/Session";
import _ from "lodash";

interface Plane {
  name: string;
  sessionList: Array<Session>;
}

export default defineComponent({
  name: "SessionCard",
  components: {},
  props: {
    plane: {
      type: Object as PropType<Plane>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();

    const doSession = (sessionId: string) => {
      router.push({
        name: "Session",
        params: { sessionId: sessionId },
      });
    };

    return {
      title: computed(() => _.startCase(_.toLower(props.plane.name))),
      doSession,
    };
  },
});

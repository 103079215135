<template>
  <div>
    <div class="card flex-md-row mx-8 box-shadow h-md-250 p-auto">
      <div class="card-body d-flex flex-column align-items-center">
        <h3 class="mb-0 p-4 text-dark">{{ title }} Plane</h3>
        <div class="d-grid gap-4 col-6 mx-auto py-4">
          <div v-for="session in plane.sessionList" :key="session.id">
            <button
              v-if="session.sessionNo == 0"
              type="button"
              class="btn btn-default demo btn-outline-primary p-4 m-10"
              @click="doSession(session.id)"
            >
              Practice Demo
            </button>
            <button
              v-else-if="session.sessionNo != 0"
              class="btn btn-default session p-4 m-10"
              type="button"
              @click="doSession(session.id)"
              :disabled="!session.isAvailable"
            >
              Session {{ session.sessionNo }}
              <p class="mb-0">
                {{ plane.sessionDataCount }}
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useRouter } from "vue-router";
import { computed, defineComponent, PropType } from "vue";
import Session from "@/models/Session";
import _ from "lodash";

interface Plane {
  name: string;
  sessionList: Array<Session>;
}

export default defineComponent({
  name: "SessionCard",
  components: {},
  props: {
    plane: {
      type: Object as PropType<Plane>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();

    const doSession = (sessionId: string) => {
      router.push({
        name: "Session",
        params: { sessionId: sessionId },
      });
    };

    return {
      title: computed(() => _.startCase(_.toLower(props.plane.name))),
      doSession,
    };
  },
});
</script>
<style scoped>
.dashboard {
  height: 100%;
}
.card {
  margin: 50px;
  border: 3px solid #000000;
}
.demo {
  border: 3px solid;
}

.btn {
  width: 90%;
}

.session {
  border: 3px solid #69b479;
}

.session:hover {
  background-color: #69b479;
}

.session:disabled {
  background-color: #adadad;
  border: 3px solid #adadad;
}
.row {
  margin: 0;
  padding: 30px;
}
</style>
